import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BaseButton } from '../components/buttons'
import { BaseInput, PasswordInput } from '../components/inputs'
import { SideMenu } from '../components/layout'
import { useQuery } from '../hooks'
import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { Invitation, User } from '../types'
import { UserRoles } from '../types/enums'
import { ApiUtility, AuthUtility, Endpoints, FormattingUtility } from '../utilities'
import { urls } from '.'
import { resetDealershipSlice, resetMessagesSlice, resetNotificationsSlice, resetOrdersSlice, resetUsersSlice } from '../store/slices'
import { useAppDispatch } from '../store/hooks'
import * as Store from '../store/slices'

enum InvitationState {
    Loading = 1,
    Error = 2,
    ConfirimInfo = 3,
    SetPassword = 4,
    VerifyPhone = 5,
}

const PwResetSchema = Yup.object().shape({
    confirmNewPassword: Yup.string().required('Required').when('password', {
        is: (val: string) => val && val.length > 0,
        then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Must match New Password'
        )
    }),
    password: Yup.string().min(8, 'Must be 8 characters').required('Required'),
});

const VerifyMFAPhoneSchema = Yup.object().shape({
    authCode: Yup.string().matches(FormattingUtility.AuthCodeRegex, 'Invalid').length(6, 'Too short').required('Required'),
});

export default function Page() {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const query = useQuery()
    const token = query.get('token')
    const [invitation, setInvitation] = useState<Invitation | null>(null)
    const [currentState, setState] = useState<InvitationState>(InvitationState.Loading)

    const checkToken = async () => {
        if (!token) {
            history.push(urls.error)
            return;
        }

        let response = await ApiUtility.Get(Endpoints.Invitation + '?token=' + token, false)

        if (!response.success) {
            history.push(urls.error)
            return;
        }

        setInvitation(response.data)
        setState(InvitationState.ConfirimInfo)
    }

    const acceptInvitation = async (password: string) => {
        let response = await ApiUtility.Post(Endpoints.Invitation + '?token=' + token, { password }, false)

        if (!response.success) {
            history.push(urls.error)
            return
        }

        // NOTE: Phone verifications disabled until 2FA is re-enabled
        // Send Phone Verification code
        await AuthUtility.SignIn(invitation!.email, password)
        // setState(InvitationState.VerifyPhone)

        // 0. Load profile
        await loadProfile()

        // 0. Redirect to dashboard
        history.push(urls.dashboard)
    }

    const verifyPhone = async (authCode: string) => {
        let success = await AuthUtility.ConfirmSignIn(authCode)

        if (!success) {
            alert('Verification failed')
        }

        await loadProfile()

        // 0. Redirect to dashboard
        history.push(urls.dashboard)
    }

    const loadProfile = async () => {
        const profileResponse = await ApiUtility.Get(Endpoints.Profile)
        if (!profileResponse.success) {
            alert('Failed to get profile.')
            return
        }

        let user = profileResponse.data as User

        // Orders
        const ordersResponse = await ApiUtility.Get(Endpoints.Orders)
        if (ordersResponse.success) {
            dispatch(Store.setOrders(ordersResponse.data))
        }

        // Notifications
        const notificationsResponse = await ApiUtility.Get(Endpoints.Notifications)
        if (notificationsResponse.success) {
            dispatch(Store.setNotifications(notificationsResponse.data))
        }

        // Threads
        const threadsResponse = await ApiUtility.Get(Endpoints.MessageThreads)
        if (threadsResponse.success) {
            dispatch(Store.setThreads(threadsResponse.data))
        }

        // Dealerships
        if (user.role === UserRoles.Admin || user.role === UserRoles.Accountant || user.role === UserRoles.CustomerService) {
            const dealershipsResponse = await ApiUtility.Get(Endpoints.Dealerships)
            if (dealershipsResponse.success) {
                dispatch(Store.setDealerships(dealershipsResponse.data))
            }
        }
        if (user.role === UserRoles.Dealer && user.dealership) {
            dispatch(Store.setDealerships([user.dealership]))
        }

        // 0. Set local data
        dispatch(Store.setCurrentUser(user))
    }

    const renderState = () => {
        switch (currentState) {
            case InvitationState.Loading:
                return (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '2em' }}>
                            <p className='card-header'>Loading</p>
                            <p><a href={urls.landing} target='_self'>Go to login page</a></p>
                        </div>
                        <div className='row flex-center'>
                            <CircularProgress style={{ color: 'var(--app-primary)' }} />
                        </div>
                    </div>
                )
            case InvitationState.Error:
                return (
                    <div>
                        <div className='row'>
                            <p className='card-header'>Error</p>
                        </div>
                        <div className='row'>
                            <p>There was an unexpected error.</p>
                        </div>
                        <div className='row'>
                            <p><a href={urls.landing} target='_self'>Go to login page</a></p>
                        </div>
                    </div>
                )
            case InvitationState.ConfirimInfo:
                return invitation && (
                    <div>
                        <div className='row' style={{ marginBottom: '0.5em' }}>
                            <p className='card-header'>Please verify your account information</p>
                        </div>
                        <div className='row' style={{ marginBottom: '0.5em' }}>
                            <p>Name: {invitation.firstName} {invitation.lastName}</p>
                        </div>
                        <div className='row' style={{ marginBottom: '0.5em' }}>
                            <p>Email: {invitation.email}</p>
                        </div>
                        <div className='row' style={{ marginBottom: '1em' }}>
                            <p>Phone Number: {invitation.phoneNumber}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p><a href={urls.landing} target='_self'>Go to login page</a></p>
                            <BaseButton text='Confirm' rightIcon='arrowRight' onClick={() => setState(InvitationState.SetPassword)} />
                        </div>
                    </div>
                )
            case InvitationState.SetPassword:
                return (
                    <div>
                        <div className='row'>
                            <p className='card-header'>Set your password</p>
                        </div>
                        <Formik
                            initialValues={{ confirmNewPassword: '', password: '' }}
                            validationSchema={PwResetSchema}
                            onSubmit={(values) => acceptInvitation(values.confirmNewPassword)}
                        >
                            {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <PasswordInput autoFocus name='password' label='Password' fullWidth defaultValue={values.password} error={touched.password && errors.password ? errors.password : ''} onBlur={handleBlur} onChange={handleChange} />
                                    </div>
                                    <div className='row'>
                                        <PasswordInput name='confirmNewPassword' label='Confirm New Password' fullWidth defaultValue={values.confirmNewPassword} error={touched.confirmNewPassword && errors.confirmNewPassword ? errors.confirmNewPassword : ''} onBlur={handleBlur} onChange={handleChange} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p><a href={urls.landing} target='_self'>Go to login page</a></p>
                                        <BaseButton text='Submit' rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )
            case InvitationState.VerifyPhone:
                return (
                    <div>
                        <div className='row'>
                            <p className='card-header'>Verify your phone number</p>
                        </div>
                        <div className='row'>
                            <p className='subtle'>We sent a verification code to your cell phone. Please enter it below to confirm your device.</p>
                        </div>
                        <Formik
                            initialValues={{ authCode: '' }}
                            validationSchema={VerifyMFAPhoneSchema}
                            onSubmit={(values) => verifyPhone(values.authCode)}
                        >
                            {({ values, touched, errors, isSubmitting, isValid, handleSubmit, handleBlur, handleChange }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <BaseInput autoFocus name='authCode' label='Code' defaultValue={values.authCode} error={touched.authCode && errors.authCode ? errors.authCode : ''} fullWidth={true} onBlur={handleBlur} onChange={handleChange} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p><a href={urls.landing} target='_self'>Go to login page</a></p>
                                        <BaseButton text='Submit' disabled={!isValid} rightIcon='arrowRight' type='submit' loading={isSubmitting} />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )
        }
    }

    const logout = async () => {
        await AuthUtility.SignOut()
        dispatch(resetDealershipSlice())
        dispatch(resetMessagesSlice())
        dispatch(resetNotificationsSlice())
        dispatch(resetOrdersSlice())
        dispatch(resetUsersSlice())
    }

    useEffect(() => {
        logout()
    }, [])

    useEffect(() => {
        checkToken();
    }, [query])

    return (
        <div className='page'>
            <SideMenu />
            <div className='page-content flex-center' style={{ backgroundImage: 'url(/images/landing.png)', backgroundSize: 'cover', height: '100%' }}>
                <div className='card login-form-container'>
                    {renderState()}
                </div>
            </div>
        </div>
    );
}